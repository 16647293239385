<template>
  <p>Logging you out...</p>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  async created() {
    await this.logout()
    this.$router.push({ name: 'login' })
  },

  methods: mapActions(['logout'])
}
</script>

<style></style>
